.center-page{
  position: relative;
  padding-top: 10%;
  pointer-events:none;
}

.text-zone{
  margin-left: 12%; 
  font-size: 22px;
  color: #ffd700;
}

.contact-form {
  width: 80%;
  margin-top: 25px;
  position: relative;


  ul {
    padding: 0;
    margin-left: 15%;

  
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block;
    position: relative;
    opacity: 0;
    animation: fadeInUp 1s 1s;
    animation-fill-mode: forwards; 
    clear: both;
    
  }

  li.half {
    width: 49%;
    margin-left: 2%;
    float: left;
    clear: none;

    &:first-child{
      margin-left: 0;
    }
  }
  }
}

input[type='text'],
input[type='email'] {
  width: 100%;
  border: 0;
  background: #115173;
  height: 50px;
  font-size: 16px;
  color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
}

textarea{
  width: 100%;
  border: 0;
  background: #115173;
  height: 50px;
  font-size: 16px;
  color: #fff;
  padding: 20px;
  min-height: 150px;
  box-sizing: border-box;
}

.flat-button{
  color: #ffd700;
  background: 0 0;
  font-size: 14px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid #ffd700;
  float: right;
  border-radius: 4px;
}


.nav{
    font-size: 25px;
    text-align: center;
    color: whitesmoke;
    animation-delay: 1s;
    position: fixed;
    margin-bottom: 10%;  
    left: 30%;
}

.disabledMenuOption{
    color:#2E640F;
    font-family: 'Titillium Web';
}

.nav {
    font-family: 'Titillium Web';
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
  }
  
  .nav * {
    box-sizing: border-box;
    transition: all .35s ease;
  }
  
  .nav li {
    display: inline-block;
    list-style: outside none none;
    margin: .5em 1em;
    padding: 0;
  }
  
  .nav a {
    padding: .5em .8em;
    color: rgba(255,255,255,.5);
    position: relative;
    text-decoration: none;
    font-size: 20px;
  }
  
  .nav a::before,
  .nav a::after {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    transition: all .35s ease;
    opacity: 0;
  }
  
  .nav a::before {
    content: '';
    right: 0;
    top: 0;
    border-top: 3px solid #3E8914;
    border-right: 3px solid #2E640F;
    transform: translate(-100%, 50%);
  }
  
  .nav a:after {
    content: '';
    left: 0;
    bottom: 0;
    border-bottom: 3px solid #2E640F;
    border-left: 3px solid #3E8914;
    transform: translate(100%, -50%)
  }
  
  .nav a:hover:before,
  .nav a:hover:after{
    transform: translate(0,0);
    opacity: 1;
  }
  
  .nav a:hover {
    color: #3DA35D;
  }