.center-page{
  position: relative;
  pointer-events:none;
}

.projectList{
  overflow-y: auto;
}

.prayingMantisProject{
  margin-left: 10%;
  animation-delay: 2.5s;
}

.prayingMantisCard{
  width: 30rem;
}

.prayingMantisImage{
  width: 75%;
  height: 75%;
  margin-left: 13%;
}

.cardTitle{
  font-size: 20px;
  color:aquamarine;
  text-align: center;
};

a:link{
  text-decoration: none;
  font-family: 'Titillium Web';
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #3E8914;
}

a:visited{
  text-decoration: none;
  color: #2E640F;
}
.cardText{
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.nav{
    font-size: 25px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: whitesmoke;
    animation-delay: 1s;
    position: fixed;
    margin-bottom: 10%;  
    left: 30%;
}

.disabledMenuOption{
    color:#2E640F;
    font-family: 'Titillium Web';
}

.nav {
    font-family: 'Titillium Web';
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
  }
  
  .nav * {
    box-sizing: border-box;
    transition: all .35s ease;
  }
  
  .nav li {
    display: inline-block;
    list-style: outside none none;
    margin: .5em 1em;
    padding: 0;
  }
  
  .nav a {
    padding: .5em .8em;
    color: rgba(255,255,255,.5);
    position: relative;
    text-decoration: none;
    font-size: 20px;
  }
  
  .nav a::before,
  .nav a::after {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    transition: all .35s ease;
    opacity: 0;
  }
  
  .nav a::before {
    content: '';
    right: 0;
    top: 0;
    border-top: 3px solid #3E8914;
    border-right: 3px solid #2E640F;
    transform: translate(-100%, 50%);
  }
  
  .nav a:after {
    content: '';
    left: 0;
    bottom: 0;
    border-bottom: 3px solid #2E640F;
    border-left: 3px solid #3E8914;
    transform: translate(100%, -50%)
  }
  
  .nav a:hover:before,
  .nav a:hover:after{
    transform: translate(0,0);
    opacity: 1;
  }
  
  .nav a:hover {
    color: #3DA35D;
  }