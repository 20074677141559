.personalPhoto{
    img{
        display: block;
        margin-top: 20px;
        position: absolute;
        top: 13%;
        right: 15%;
        width: 20%;
        height: auto;
    }
}

.quotation{
  color: whitesmoke;
  font-size: 25px;
  line-height: 1.5;
  position: fixed;
  margin-top: 17%;  
  left: 32%;
  animation-delay: 1.5s;
}

.quote{
  text-align: right;
}

.highlightedItem{
  color: yellow;
}

.animate__animated.animate__fadeIn{
    animation-delay: 1s;
}

// .nav{
//     font-size: 25px;
//     text-align: center;
//     animation-delay: 1s;
//     position: fixed;
//     margin-bottom: 10%;  
//     left: 30%;
//     }

.disabledMenuOption{
  color:#2E640F;
  font-family: 'Titillium Web';
}

.nav {
    font-family: 'Titillium Web';
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
}
  
.nav * {
  box-sizing: border-box;
  transition: all .35s ease;
}
  
.nav li {
  display: inline-block;
  list-style: outside none none;
  margin: .5em 1em;
  padding: 0;
  }
  
.nav a {
  padding: .5em .8em;
  color: rgba(255,255,255,.5);
  position: relative;
  text-decoration: none;
  font-size: 20px;
}
  
  .nav a::before,
  .nav a::after {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    transition: all .35s ease;
    opacity: 0;
  }
  
  .nav a::before {
    content: '';
    right: 0;
    top: 0;
    border-top: 3px solid #3E8914;
    border-right: 3px solid #2E640F;
    transform: translate(-100%, 50%);
  }
  
  .nav a:after {
    content: '';
    left: 0;
    bottom: 0;
    border-bottom: 3px solid #2E640F;
    border-left: 3px solid #3E8914;
    transform: translate(100%, -50%)
  }
  
  .nav a:hover:before,
  .nav a:hover:after{
    transform: translate(0,0);
    opacity: 1;
  }
  
  .nav a:hover {
    color: #3DA35D;
  }